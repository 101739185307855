import { fetchAndCheckResponse, showUserAlert } from ".";
import { HeaderBuilder, UrlBuilder } from "./rest-request-builders";

const ADMIN_PATH = "admin";

export function exportDatabase() {
	const url = new UrlBuilder()
		.path(ADMIN_PATH)
		.path('export')
		.build();

	const headers = new HeaderBuilder()
		.jwt()
		.build();

	const payload = {
		method: 'POST',
		headers: headers,
	};

	return fetchAndCheckResponse(url, payload)
		.then(resp => resp.text())
		.then(text => showUserAlert('success', text))
		.catch(handleExportError);
}

function handleExportError(response: Response) {
		showUserAlert('error', `Export failed with error code ${response.status}`);
}
