import { MessageLevel } from "non-ui/redux/alerts";
import { showAlert } from "non-ui/redux/alerts/alert-actions";
import { FerretAction } from "non-ui/redux/ferrets";
import Store from "non-ui/redux/store";
import { Dispatch } from "redux";
import { fetchFerrets } from "./ferrets";
import { clearUserToken } from "./login";
import { HeaderBuilder } from "./rest-request-builders";
import { fetchGraphDisplayData, fetchTableDisplayData } from "./weights";
import { GraphDisplayDataAction } from "non-ui/redux/graphdisplaydata";
import { TableDisplayDataAction } from "non-ui/redux/tabledisplaydata";

export type ApiRequest = () => void;
export type AlertSuppressor = (response: Response) => boolean;

const NoSuppression: AlertSuppressor = () => true;
export const SuppressIf401: AlertSuppressor = (response: Response) => response.status !== 401;

export function fetchEverything() {
	return (dispatch: Dispatch<FerretAction | GraphDisplayDataAction | TableDisplayDataAction>) =>
		Promise.all([
			fetchFerrets()(dispatch),
			fetchGraphDisplayData()(dispatch),
			fetchTableDisplayData()(dispatch)
		]).catch(resp => handleRestError(resp, 'full refresh'));
}


export function showUserAlert(level: MessageLevel, message: string) {
	Store.dispatch(showAlert(level, message));
}

export function handleRestError(response: Response, attemptedOperation: string, showAlert: AlertSuppressor = NoSuppression) {
	if (showAlert(response))
		showUserAlert('error', `failed to ${attemptedOperation} - error code ${response.status}`)
}

export function convertJsonResponse<T>(response: Response, logPayload: boolean = false): Promise<T> {
	if (logPayload)
		response.clone().text().then(result => console.log('raw response is ' + result));

		const jsn = response.json() as unknown;
		return Promise.resolve(jsn as T);
	}


export function fetchAndCheckResponse(url: string, data: RequestInit = getRequestWithJwt()): Promise<Response> {
	// console.trace('sending ' + JSON.stringify(data) + ' to ' + url);
	return fetch(url, data)
		.then(r => rejectNon2XXResponse(r));
}

function rejectNon2XXResponse(res: Response): Promise<Response> {
	return new Promise((resolve, reject) => {
		console.debug(`received resp ${res.status}`);
		if (res.status < 300)
			resolve(res);
		else {
			if (res.status === 401)
				clearUserToken();

			logResponse(res.clone());
			reject(res);
		}
	});
}

function logResponse(res: Response): Response {
	var logstr = logResponseCode(res);
	logstr = logstr + '\n' + logResponseHeaders(res.headers);
	res.clone().text().then(result => console.error(`Remote server errror:\n${logstr}\nBody: ${result}`));
	return res;
}

function logResponseCode(res: Response): string {
	return `Code: ${res.status}\nCode text: ${res.statusText}`;
}

export function logResponseHeaders(headers: Headers) : string {
	var headerStr = '';
	headers.forEach((val, key) => headerStr += `Header: ${key} -> ${val}\n`);
	return headerStr;
}

export function createPutPayload(object: object): RequestInit {
	return createJSONPayload(object, 'PUT');
}

export function createPostPayload(object: object): RequestInit {
	return createJSONPayload(object, 'POST');
}

function createJSONPayload(object: object, method: string): RequestInit {
	const objectAsString = JSON.stringify(object);

	const headers = new HeaderBuilder()
		.withJsonContentType()
		.acceptJsonContentType()
		.jwt()
		.build();

	return {
		method: method,
		headers: headers,
		body: objectAsString
	}
}

function getRequestWithJwt(): RequestInit {
	const headers = new HeaderBuilder()
		.acceptJsonContentType()
		.jwt()
		.build()

	return {
		method: 'GET',
		headers: headers
	}
}