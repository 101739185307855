import { GraphDisplayData } from "non-ui/data";
import { Reducer } from "redux";
import { GraphDisplayDataAction, REPLACE_GRAPH_DISPLAY_DATA } from ".";


function reducer(state: GraphDisplayData[] = [], { type, payload }: GraphDisplayDataAction): GraphDisplayData[] {
	switch (type) {
		case REPLACE_GRAPH_DISPLAY_DATA:
			return payload;
		default:
			return state;
	}
}

const graphDisplayData: Reducer<GraphDisplayData[], GraphDisplayDataAction> = reducer;

export default graphDisplayData;