export type MessageLevel = 'none' | 'success' | 'info' | 'warning' | 'error'; 

export const SHOW_ALERT : string = 'alerts:showalert';
export const CLEAR_ALERT : string = 'alerts:clearalert';

export interface AlertData {
	level: MessageLevel;
	message: string;
}

export interface AlertAction {
  type: typeof SHOW_ALERT;
  payload: AlertData;
}