import { TableRowData } from "./data";

export interface FerretTableRow {
	date : string;
	weightOf : (ferretName : string) => string|null;
}

const DateFormat = new Intl.DateTimeFormat('en-GB');

export function toTableData(tableRowDataList : TableRowData[]) : FerretTableRow[] {
	return tableRowDataList
		.map(toTableRow);
}

function toTableRow(tableRowData : TableRowData) : FerretTableRow {
	const weightByName = (ferretName: string) => {
		const matches = tableRowData.ferretWeights.filter(fw => fw.ferretName === ferretName)
		return matches.length === 0
			? null
			: matches[0].ferretWeight.toString();
	}

	return {
		date : DateFormat.format(tableRowData.date),
		weightOf: weightByName
	}
}
