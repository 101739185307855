import { LocalStore, UserRole, UserToken } from "non-ui/data";
import { replaceLocalStore } from "non-ui/redux/localstore/localstore-actions";
import Store from "non-ui/redux/store";
import { convertJsonResponse, createPostPayload, fetchAndCheckResponse, fetchEverything, showUserAlert } from ".";
import { UrlBuilder } from "./rest-request-builders";

interface JwtAuthority {
	authority: string;
}

interface JwtUser {
	username: string;
	firstname: string;
	lastname: string;
	authorities: JwtAuthority[];
	role: UserRole;
	enabled: boolean;
}

interface JwtResponse {
	user: JwtUser;
	token: string;
}

export function login(user: string, password: string, redirector: () => void) {
	const url = new UrlBuilder()
		.path('auth')
		.build();

	const creds = {
		username: user,
		password: password
	};

	const payload = createPostPayload(creds);

	return fetchAndCheckResponse(url, payload)
		.then(resp => convertJsonResponse<JwtResponse>(resp))
		.then(convertToUserToken)
		.then(updateUserToken)
		.then(postLoginRefresh)
		.then(redirector)
		.catch(handleLoginError);

}

export function clearUserToken() {
	const localStore: LocalStore = Store.getState().localstore;
	Store.dispatch(replaceLocalStore({ ...localStore, userToken: undefined }));
}

function convertToUserToken(jwtToken : JwtResponse) : UserToken {
	return {
		username: jwtToken.user.username,
		role: jwtToken.user.role,
		token: jwtToken.token
	}
}

function updateUserToken(userToken: UserToken) {
	const localStore: LocalStore = Store.getState().localstore;
	Store.dispatch(replaceLocalStore({ ...localStore, userToken: userToken }));
}

function handleLoginError(response: Response) {
	if (response.status === 401)
		showUserAlert('error', 'Login failed');
	else
		showUserAlert('error', `Bad shit - error code ${response.status}`);
}

function postLoginRefresh() {
	fetchEverything()(Store.dispatch);
}
