import { TablePagination } from "@material-ui/core";
import React from "react";
import TablePaginationActions from "./table-pagination-actions";

interface PaginationWrapperProps {
	numberOfColumns: number;
	totalRows: number;
	page: number;
	rowsPerPage: number;
	setPage: (page: number) => void;
	setRowsPerPage: (rpp : number) => void;
}

function PaginationWrapper({ numberOfColumns, totalRows, page, rowsPerPage, setPage, setRowsPerPage }: PaginationWrapperProps) {
	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<TablePagination
			rowsPerPageOptions={[5, 10, 15, 20, { label: 'All', value: -1 }]}
			colSpan={numberOfColumns}
			count={totalRows}
			rowsPerPage={rowsPerPage}
			page={page}
			SelectProps={{
				inputProps: { 'aria-label': 'rows per page' },
				native: true,
			}}
			onChangePage={handleChangePage}
			onChangeRowsPerPage={handleChangeRowsPerPage}
			ActionsComponent={TablePaginationActions}
		/>
	);
}


export default PaginationWrapper;
