import { Card, CardActions, CardContent, FormControlLabel, makeStyles, MenuItem, Select, Switch, Typography } from '@material-ui/core';
import { LocalStore } from 'non-ui/data';
import React from 'react';
import { Styles } from '.';

const useStyles = makeStyles({
	select: {
		paddingTop: 30,
		width: '90%',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
});


interface SwitchesCardProps {
	localstore: LocalStore;
	updateLocalStore: (localstore: LocalStore) => void;
	classes: Styles;
}

const PAGINATED = 'paginated';
const SLIDER = 'slider';

function SwitchesCard({ localstore, updateLocalStore, classes }: SwitchesCardProps) {
	const localClasses = useStyles();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		updateLocalStore({ ...localstore, [event.target.name]: event.target.checked });
	};

	const handleTableTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as string;
		const usePaginated = value === PAGINATED;
		updateLocalStore({ ...localstore, usePaginatedTable: usePaginated });
	};

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography variant="h5" component="h2">
					Settings
				</Typography>
				<div>
					<FormControlLabel
						control={<Switch checked={localstore.includeInactiveFerrets} onChange={handleChange} name="includeInactiveFerrets" />}
						label="Include inactive ferrets"
					/>
				</div>
				<div>
					<FormControlLabel
						control={<Switch checked={localstore.linesAsSplines} onChange={handleChange} name="linesAsSplines" />}
						label="Display graph lines as splines"
					/>
				</div>
				<div className={localClasses.select}>
				<Typography component="h6">
					Table Style
				</Typography>
					<Select
						labelId="table-type-label"
						id="table-type"
						value={localstore.usePaginatedTable ? PAGINATED : SLIDER}
						onChange={handleTableTypeChange}
					>
						<MenuItem value={PAGINATED}>Paginated</MenuItem>
						<MenuItem value={SLIDER}>Slider Controlled</MenuItem>
					</Select>
				</div>
			</CardContent>
			<CardActions />
		</Card>
	);
}



export default SwitchesCard;