import { Reducer } from "redux";
import { TableDisplayDataAction, REPLACE_TABLE_DISPLAY_DATA } from ".";
import { TableRowData } from "non-ui/data";


function reducer(state: TableRowData[] = [], { type, payload }: TableDisplayDataAction): TableRowData[] {
	switch (type) {
		case REPLACE_TABLE_DISPLAY_DATA:
			return payload;
		default:
			return state;
	}
}

const tableDisplayData: Reducer<TableRowData[], TableDisplayDataAction> = reducer;

export default tableDisplayData;