import React from 'react';

import { NavLink } from "react-router-dom";
import { Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	menuButton: {
		height: '100%',
		color: 'white',
		marginRight: theme.spacing(2),
		textDecoration: 'none',
	},
	menuButtonActive: {
		color: 'yellow',
	},
}));

interface MenuItemProps {
	routerPath: string;
	exact?: boolean;
	children: React.ReactNode;
}

function TopMenuItem({ routerPath, exact = false, children  }: MenuItemProps) {
	const classes = useStyles();

	return (
		<Link component={NavLink} exact={exact} to={routerPath} className={classes.menuButton} activeClassName={classes.menuButtonActive}>
			{children}
		</Link>
	);
}

export default TopMenuItem;