import { makeStyles, TableRow } from "@material-ui/core";
import { FerretTableCell } from "components/ferret-table-cell";
import { Ferret } from "non-ui/data";
import { FerretTableRow } from "non-ui/tabledata";
import React from "react";

interface FerretTableBodyProps {
	ferrets: Ferret[];
	tableRowData: FerretTableRow[];
	emptyRows: number;
}

const useStyles = makeStyles({
	emptyCell: {
		borderBottom: 'none',
	}
});

function FerretTableBody({ ferrets, tableRowData, emptyRows }: FerretTableBodyProps) {
	const classes = useStyles();
	const numCols = ferrets.length + 1;

	return (
		<>
			{tableRowData.map(
				dr => (
					<TableRow>
						<FerretTableCell align="right">{dr.date}</FerretTableCell>
						{ferrets.map(ferret => (
							<FerretTableCell align="right">{dr.weightOf(ferret.ferretName)}</FerretTableCell>
						))}
					</TableRow>
				)
			)}
			{Array(emptyRows).fill('').map(i => generateEmptyRow(numCols, classes.emptyCell))}
		</>
	);
}

function generateEmptyRow(numCols: number, classname: string) {
	return (
		<TableRow>
			<FerretTableCell colSpan={numCols} classes={{ body: classname }}>&nbsp;</FerretTableCell>
		</TableRow>
	);
}

export default FerretTableBody;
