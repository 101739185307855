import { GRAPH_LINES_AS_SPLINES, INCLUDE_INACTIVE_FERRETS, LocalStoreAction, UPDATE_LOCAL_STORE, USER_TOKEN_KEY, USE_PAGINATED_TABLE } from ".";
import { LocalStore, UserToken } from "non-ui/data";

export function replaceLocalStore(localStore: LocalStore): LocalStoreAction {
	localStorage.setItem(INCLUDE_INACTIVE_FERRETS, String(localStore.includeInactiveFerrets));
	localStorage.setItem(GRAPH_LINES_AS_SPLINES, String(localStore.linesAsSplines));
	localStorage.setItem(USE_PAGINATED_TABLE, String(localStore.usePaginatedTable));
	updateUserToken(localStore.userToken);

	return {
		type: UPDATE_LOCAL_STORE,
		payload: localStore
	};
}

function updateUserToken(userToken?: UserToken) {
	if (userToken?.token) 
		localStorage.setItem(USER_TOKEN_KEY, userToken.token);
	else
		localStorage.removeItem(USER_TOKEN_KEY);
}