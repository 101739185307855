import { createStyles, Grid, makeStyles, Paper, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow, Theme } from "@material-ui/core";
import { TableRowData } from "non-ui/data";
import { FerretTableRow, toTableData } from "non-ui/tabledata";
import React from "react";
import { FerretWeightTableProps, LargeScreenBreak } from ".";
import FerretTableBody from "./table-body";
import FerretTableHead from "./table-head";
import PaginationWrapper from "./table-pagination";


const useStyles = makeStyles((theme: Theme) =>
createStyles({
	table: {
			[theme.breakpoints.up(LargeScreenBreak)]: {
				minWidth: 450,
			},
		},
	}));

function PaginatedFerretWeightTable({ ferrets, tableDataList }: FerretWeightTableProps) {
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [page, setPage] = React.useState(0);
	const classes = useStyles();
	const totalRows = tableDataList.length
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalRows - page * rowsPerPage);
	const numCols = ferrets.length + 1;
	const selectedData = selectTableRows(tableDataList, page, rowsPerPage);
	const displayRows: FerretTableRow[] = toTableData(selectedData);

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="ferret weights" size="small">
				<TableHead>
					<FerretTableHead ferrets={ferrets} />
				</TableHead>
				<TableBody>
					<FerretTableBody ferrets={ferrets} tableRowData={displayRows} emptyRows={emptyRows} />
				</TableBody>
				<TableFooter>
					<TableRow>
						<PaginationWrapper numberOfColumns={numCols} totalRows={totalRows} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

function selectTableRows(tableRows: TableRowData[], page: number, rowsPerPage: number): TableRowData[] {
	if (rowsPerPage < 1)
		return tableRows;

	const lowerIndex = page * rowsPerPage;
	const upperIndex = lowerIndex + rowsPerPage;
	return tableRows.slice(lowerIndex, upperIndex);
}

export default PaginatedFerretWeightTable;
