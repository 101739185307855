import { TableRow } from "@material-ui/core";
import { FerretTableCell } from "components/ferret-table-cell";
import { Ferret } from "non-ui/data";
import React from "react";

interface FerretTableHeadProps {
	ferrets: Ferret[];
}

function FerretTableHead({ ferrets }: FerretTableHeadProps) {
	return (
		<TableRow>
			<FerretTableCell align="right">Date</FerretTableCell>
			{ferrets.map(
				f => (
					<FerretTableCell align="right">{f.ferretName}</FerretTableCell>
				))}
		</TableRow>
	);
}

export default FerretTableHead;
