import { combineReducers } from "redux";
import ferrets from './ferrets/ferret-reducers';
// import weightDates from './weightdates/weightdates-reducers';
import localstore from './localstore/localstore-reducers';
import alerts from "./alerts/alert-reducers";
import graphDisplayData from './graphdisplaydata/graphdisplaydata-reducers';
import tableDisplayData from './tabledisplaydata/tabledisplaydata-reducers';

export const combinedReducers = combineReducers({
	ferrets,
	// weightDates,
	graphDisplayData,
	tableDisplayData,
	localstore,
	alerts,
});
