import { createStyles, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Theme, Typography } from "@material-ui/core";
import { TableRowData } from "non-ui/data";
import { FerretTableRow, toTableData } from "non-ui/tabledata";
import React from "react";
import { FerretWeightTableProps } from ".";
import DateRangeSelector from "./date-range-selector";
import FerretTableBody from "./table-body";
import FerretTableHead from "./table-head";

const LargeScreenBreak = 'md';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		table: {
			[theme.breakpoints.up(LargeScreenBreak)]: {
				minWidth: 450,
			},
		},
		footer: {
			display: 'flex',
			justifyContent: 'flex-end',
			marginLeft: 30
		},
	}));

function SliderControlledFerretWeightTable({ ferrets, tableDataList }: FerretWeightTableProps) {
	const [indices, setIndices] = React.useState([0, 5]);
	const classes = useStyles();
	const totalRows = tableDataList.length
	const emptyRows = calculateEmptyRows(totalRows, indices[0], indices[1]);
	const numCols = ferrets.length + 1;
	const selectedData = selectTableRows(tableDataList, indices[0], indices[1]);
	const displayRows: FerretTableRow[] = toTableData(selectedData);

	const updateIndices = (lowerIndex: number, upperIndex: number) => {
		setIndices([lowerIndex, upperIndex]);
	}

	return (
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="ferret weights" size="small">
							<TableHead>
								{tableDataList.length > 2 &&
									<TableRow>
										<TableCell colSpan={numCols}>
											<DateRangeSelector tableDataList={tableDataList} setIndices={updateIndices} />
										</TableCell>
									</TableRow>
								}
								<FerretTableHead ferrets={ferrets} />
							</TableHead>
							<TableBody>
								<FerretTableBody ferrets={ferrets} tableRowData={displayRows} emptyRows={emptyRows} />
							</TableBody>
							<TableFooter>
							<TableRow>
										<TableCell colSpan={numCols}>
											<div className={classes.footer}><Typography component="h6">Showing {displayRows.length} of {totalRows}</Typography></div>
										</TableCell>
									</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
	);
}

function calculateEmptyRows(totalRows: number, lowIndex: number, hiIndex: number): number {
	const displayRows = hiIndex - lowIndex;
	const empty = displayRows - totalRows;
	return (empty < 0) ? 0 : empty;
}

function selectTableRows(tableRows: TableRowData[], lowerIndex: number, upperIndex: number): TableRowData[] {
	return tableRows.slice(lowerIndex, upperIndex);
}

export default SliderControlledFerretWeightTable;
