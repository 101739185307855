import { Ferret } from "non-ui/data";
import { FerretAction } from "non-ui/redux/ferrets";
import { replaceFerrets } from "non-ui/redux/ferrets/ferret-actions";
import Store from "non-ui/redux/store";
import { Dispatch } from "redux";
import { convertJsonResponse, createPostPayload, createPutPayload, fetchAndCheckResponse, fetchEverything, handleRestError, SuppressIf401 } from ".";
import { HeaderBuilder, UrlBuilder } from "./rest-request-builders";

const FERRET_PATH = 'ferret';

export function fetchFerrets() {
	const url = new UrlBuilder()
		.path(FERRET_PATH)
		.appendInactiveFerretsParameter()
		.build();

	return (dispatch: Dispatch<FerretAction>) => fetchAndCheckResponse(url)
		.then(resp => convertJsonResponse<Ferret[]>(resp))
		.then(ferrets => dispatch(replaceFerrets(ferrets)))
		.catch(resp => handleRestError(resp, 'fetch ferrets', SuppressIf401));
}

export function deleteFerret(ferret: Ferret) {
	deleteFerretById(ferret.ferretId!);
}

export function deleteFerretById(ferretId: number) {
	const url = ferretIdUrl(ferretId);

	const headers = new HeaderBuilder()
		.jwt()
		.build();

	const payload = {
		method: 'DELETE',
		headers: headers,
	};

	return (dispatch: Dispatch<FerretAction>) => fetchAndCheckResponse(url, payload)
		.then(() => fetchFerrets()(dispatch))
		.catch(resp => handleRestError(resp, 'delete ferret'));
}

export function addFerret(ferret: Ferret) {
	const url = new UrlBuilder()
		.path(FERRET_PATH)
		.build();

	const payload = createPostPayload(ferret);

	fetchAndCheckResponse(url, payload)
		.then(resp => convertJsonResponse<Ferret>(resp))
		.then(() => fetchEverything()(Store.dispatch))
		.catch(resp => handleRestError(resp, 'add ferret'));
}

export function updateFerret(ferret: Ferret) {
	const url = ferretIdUrl(ferret.ferretId!);

	const dispatch = Store.dispatch;
	const payload = createPutPayload(ferret);

	fetchAndCheckResponse(url, payload)
		.then(resp => convertJsonResponse<Ferret>(resp))
		.then(() => fetchEverything()(dispatch))
		.catch(resp => handleRestError(resp, 'update ferret'));
}

function ferretIdUrl(ferretId: number) {
	return new UrlBuilder()
		.path(FERRET_PATH)
		.path(ferretId)
		.build();
}

// function replaceInFerretList(ferret: Ferret): Ferret[] {
// 	const currentFerrets: Ferret[] = Store.getState().ferrets;
// 	const newFerretList: Ferret[] = [ferret];

// 	currentFerrets
// 		.filter(f => f.ferretId !== ferret.ferretId)
// 		.forEach(f => newFerretList.push(f));

// 	return newFerretList.sort((f1, f2) => f1.ferretOrder - f2.ferretOrder);
// }