import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { Ferret, TableRowData } from "non-ui/data";
import { FerretState } from "non-ui/redux/store";
import React from "react";
import { connect } from "react-redux";
import PaginatedFerretWeightTable from "./paginated-ferret-weight-table";
import SliderControlledFerretWeightTable from "./slider-controlled-ferret-weight-table";

export const LargeScreenBreak = 'md';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		total: {
			marginTop: 5,
			[theme.breakpoints.up(LargeScreenBreak)]: {
				marginTop: 30,
			},
		},
		root: {
			width: '100%',
			[theme.breakpoints.up(LargeScreenBreak)]: {
				minHeight: 600,
				alignItems: 'center',
			},
		},
	}));


export interface FerretWeightTableProps {
	ferrets: Ferret[];
	tableDataList: TableRowData[];
}

interface SelectableFerretWeightTableProps extends FerretWeightTableProps {
	usePaginatedTable: boolean;
}

function FerretWeightTable({ ferrets, tableDataList, usePaginatedTable }: SelectableFerretWeightTableProps) {
	const classes = useStyles();

	return (
		<Grid item container className={classes.total}>
			<Grid item xs={false} md={2} />
			<Grid item xs={12} md={8}>
				<div className={classes.root}>
					{usePaginatedTable
						? <PaginatedFerretWeightTable ferrets={ferrets} tableDataList={tableDataList} />
						: <SliderControlledFerretWeightTable ferrets={ferrets} tableDataList={tableDataList} />
					}
				</div>
			</Grid>
			<Grid item xs={false} md={2} />
		</Grid>
	);


}

const mapStateToProps = (state: FerretState) => {
	return { ferrets: state.ferrets, tableDataList: state.tableDisplayData, usePaginatedTable: state.localstore.usePaginatedTable };
}

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(FerretWeightTable);
