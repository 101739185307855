import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface DeleteWarningDialogProps {
	open: boolean;
	idOfFerretToBeDeleted: number;
	handleClose: (deleteAccepted: boolean, idOfFerretToBeDeleted?: number) => void;
}

export default function DeleteWarningDialog({open, idOfFerretToBeDeleted, handleClose} : DeleteWarningDialogProps) {
  return (
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
						This will delete the ferret entirely from the database, and should only be done if you have added a ferret record by mistake. Are you
						sure you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary" autoFocus>
            Don't Delete
          </Button>
          <Button onClick={() => handleClose(true, idOfFerretToBeDeleted)} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
  );
}
