import { CssBaseline, Grid, ThemeProvider } from '@material-ui/core';
import ErrorBoundary from 'error-boundary';
import { UserToken } from 'non-ui/data';
import { FerretState } from 'non-ui/redux/store';
import { fetchEverything } from 'non-ui/rest';
import React from 'react';
import { connect } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import Content from 'screens/content';
import TopBar from 'screens/topbar';
import theme from 'theme';

interface AppProps {
	userToken?: UserToken;
	fetchEverythingDispatch: () => Promise<any>;
}


function App({ userToken, fetchEverythingDispatch }: AppProps) {
	const graphpath = '/';
	const loginpath = '/login';
	const tablepath = '/table';
	const ferretspath = '/ferrets'
	const settingspath = '/settings';

	React.useEffect(() => fetchEverything()
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, []);

	const fetchEverything = () => {
		fetchEverythingDispatch();
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<ErrorBoundary>
				<HashRouter basename='/'>
					<Grid container direction="column">
						{userToken &&
						(<Grid item>
							<TopBar graphpath={graphpath} tablepath={tablepath} ferretspath={ferretspath} settingspath={settingspath} />
						</Grid>)
						}
						<Content graphpath={graphpath} tablepath={tablepath} ferretspath={ferretspath} settingspath={settingspath} loginPath={loginpath} />
					</Grid>
				</HashRouter>
			</ErrorBoundary>
		</ThemeProvider>
	);
}

const mapStateToProps = (state: FerretState) => {
	return { userToken: state.localstore.userToken };
}


const mapActionsToProps = {
	fetchEverythingDispatch: fetchEverything,
};

export default connect(mapStateToProps, mapActionsToProps)(App);
