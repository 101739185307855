import { Reducer } from "redux";
import { FerretAction, REPLACE_FERRETS } from ".";
import { Ferret } from "non-ui/data";

function reducer(state: Ferret[] = [], { type, payload }: FerretAction): Ferret[] {
	switch (type) {
		case REPLACE_FERRETS:
			return payload;
		default:
			return state;
	}
}

const ferrets: Reducer<Ferret[], FerretAction> = reducer;

export default ferrets;