import { Grid, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import { LocalStore } from "non-ui/data";
import { replaceLocalStore } from "non-ui/redux/localstore/localstore-actions";
import { FerretState } from "non-ui/redux/store";
import { fetchEverything } from "non-ui/rest";
import { connect } from "react-redux";
import ActionsCard from "./actions-card";
import SwitchesCard from "./switches-card";

export type Styles = ClassNameMap<"root">;

const useStyles = makeStyles({
	root: {
		minWidth: 275,
		margin: 20
	},
});


interface SettingsProps {
	localstore: LocalStore;
	replaceLocalStore: (localstore: LocalStore) => void;
	fetchEverythingDispatch: () => Promise<any>;
}

function Settings({ localstore, replaceLocalStore, fetchEverythingDispatch }: SettingsProps) {
	const classes: Styles = useStyles();

	const fullRefresh = () => {
		fetchEverythingDispatch();
	}

	const updateLocalStore = (localstore: LocalStore) => {
		replaceLocalStore(localstore);
		fullRefresh();
	}


	return (
		<Grid container direction="row">
			<Grid item xs={false} lg={2} />
			<Grid item xs={12} lg={8} container>
				<SwitchesCard localstore={localstore} updateLocalStore={updateLocalStore} classes={classes} />
				<ActionsCard classes={classes} />
			</Grid>
			<Grid item xs={false} lg={2} />
		</Grid>
	);
}

const mapStateToProps = (state: FerretState) => {
	return { localstore: state.localstore };
}

const mapActionsToProps = {
	replaceLocalStore: replaceLocalStore,
	fetchEverythingDispatch: fetchEverything
};

export default connect(mapStateToProps, mapActionsToProps)(Settings);


