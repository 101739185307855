import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import { nullAlertData } from './alerts/alert-reducers';
import { combinedReducers } from "./combined-reducers";
import { INITIAL_LOCAL_STORE } from './localstore/localstore-reducers';

export type FerretState = ReturnType<typeof combinedReducers>;

const initial : FerretState = {
	ferrets: [],
	// weightDates: [],
	graphDisplayData: [],
	tableDisplayData: [],
	localstore: INITIAL_LOCAL_STORE,
	alerts: nullAlertData
}

const allStoreEnhancers = composeWithDevTools(
	applyMiddleware(thunk),
);

const Store = createStore(combinedReducers, initial, allStoreEnhancers);

export default Store;

