import { TableCell } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core/styles";


export const FerretTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.common.black,
			fontWeight: 'bold',
			borderBottomColor: '#000000',
			padding: 0,
		},
		body: {
			backgroundColor: '#F0E68C',
			fontSize: 14,
			borderBottomColor: '#000000',
			padding: 4,
		},
	}),
)(TableCell);
