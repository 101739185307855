import { config } from 'configuration';
import Store from 'non-ui/redux/store';
const SCHEME = config.ferretServer.scheme;
const BACKEND_IP_ADDRESS = config.ferretServer.ipAddress;
const BACKEND_PORT = config.ferretServer.port;

const BACKEND_ADDRESS = `${SCHEME}://${BACKEND_IP_ADDRESS}:${BACKEND_PORT}`;

export class UrlBuilder {
	private url: string;
	private paramslist: string[];

	constructor() {
		this.url = BACKEND_ADDRESS;
		this.paramslist = [];
	}

	path(path: string | number): UrlBuilder {
		this.url += ('/' + path);
		return this;
	}

	appendInactiveFerretsParameter(): UrlBuilder {
		return this.parameter('includeInactive', String(Store.getState().localstore.includeInactiveFerrets))
	}

	parameter(key: string, value: string): UrlBuilder {
		this.paramslist.push(key + '=' + value);
		return this;
	}

	build(): string {
		var url = this.url;

		if (this.paramslist.length > 0)
			url = url + '?' + this.paramslist.join('&');

		return url;
	}
}

export class HeaderBuilder {
	private headers: Headers = new Headers();

	withJsonContentType(): HeaderBuilder {
		return this.contentType('application/json');
	}

	acceptJsonContentType(): HeaderBuilder {
		return this.add('Accept', 'application/json');
	}

	contentType(value: string): HeaderBuilder {
		return this.add('Content-Type', value);
	}

	jwt(): HeaderBuilder {
		const token : string|undefined = Store.getState().localstore.userToken?.token;

		if (token)
			return this.add('Authorization', `Bearer ${token}`);
		return this;
	}

	add(hkey: string, value: string): HeaderBuilder {
		this.headers.set(hkey, value);
		return this;
	}

	build(): HeadersInit {
		return this.headers;
	}
}