import { Grid } from '@material-ui/core';
import UserMessage from 'components/user-message';
import { UserToken } from 'non-ui/data';
import { PrivateRoute } from 'non-ui/login/private-route';
import { FerretState } from 'non-ui/redux/store';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import Ferrets from 'screens/ferrets';
import Settings from 'screens/settings';
import FerretWeightsGraph from 'screens/weights-graph';
import FerretWeightTable from 'screens/weights-table';
import Login from './login';
import { MenuProps } from './topbar';

interface ContentProps extends MenuProps {
	userToken?: UserToken;
	loginPath: string;
}

function Content({ userToken, graphpath, tablepath, ferretspath, settingspath, loginPath }: ContentProps) {
	return (
		<Grid item>
			<Switch>
				<PrivateRoute
					exact path={graphpath}
					component={FerretWeightsGraph}
					loginPath={loginPath}
					userToken={userToken}
				/>
				<PrivateRoute
					path={tablepath}
					component={FerretWeightTable}
					loginPath={loginPath}
					userToken={userToken}
				/>
				<PrivateRoute
					path={ferretspath}
					component={Ferrets}
					loginPath={loginPath}
					userToken={userToken}
				/>
				<PrivateRoute
					path={settingspath}
					component={Settings}
					loginPath={loginPath}
					userToken={userToken}
				/>
				<Route
					path={loginPath}
					render={() => (<Login />)}
				/>
			</Switch>
			<UserMessage />
		</Grid>
	);
}

const mapStateToProps = (state: FerretState) => {
	return { userToken: state.localstore.userToken };
}

export default connect(mapStateToProps, null)(Content);

