import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { AlertData, MessageLevel } from 'non-ui/redux/alerts';
import { clearAlert } from 'non-ui/redux/alerts/alert-actions';
import { FerretState } from 'non-ui/redux/store';

function Alert(props: JSX.IntrinsicAttributes & AlertProps) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}));

interface UserMessageProps {
	alertData: AlertData;
	clearAlert: () => void;
}

function levelToSeverity(level: MessageLevel): Color {
	switch (level) {
		case 'success':
			return 'success';
		case 'warning':
			return 'warning';
		case 'error':
			return 'error';
		case 'none':
		case 'info':
		default:
			return 'info';
	}

}

function UserMessage({ alertData, clearAlert }: UserMessageProps) {
	const classes = useStyles();
	const open = alertData.level !== 'none';
	const severity = levelToSeverity(alertData.level)
	const message = alertData.message;

	const handleClose = () => {
		clearAlert();
	};

	return (
		<div className={classes.root}>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={severity}>
					{message}
				</Alert>
			</Snackbar>
		</div>
	);
}

const mapStateToProps = (state: FerretState) => {
	return { alertData: state.alerts };
}

const mapActionsToProps = {
	clearAlert: clearAlert
};

export default connect(mapStateToProps, mapActionsToProps)(UserMessage);

