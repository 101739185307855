import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

type OpenCloseFunction = (open : boolean) => (e: React.KeyboardEvent | React.MouseEvent) => void;

export type Anchor = 'left' | 'right' | 'bottom' | 'top' | undefined;

interface SwipeableTemporaryDrawerProps {
	openDrawer : boolean;
	setOpenDrawer: (open : boolean) => void;
	anchor?: Anchor;
	children: React.ReactNode;
}

export default function SwipeableTemporaryDrawer({openDrawer, setOpenDrawer, anchor='left', children} : SwipeableTemporaryDrawerProps) {
	const toggleDrawer : OpenCloseFunction = (open: boolean) =>	() => setOpenDrawer(open);

	return (
		<div>
			<SwipeableDrawer
				anchor={anchor}
				open={openDrawer}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
			>
				{children}
			</SwipeableDrawer>
		</div>
	);
}
