import { Card, CardActions, CardContent, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import { Checkbox, TextField } from 'formik-material-ui';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core';
import { Ferret } from 'non-ui/data';
import React, { useState } from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { isBlankOrEmpty } from 'non-ui/utilities';
import { updateFerret } from 'non-ui/rest/ferrets';
import DeleteWarningDialog from './delete-warning-dialog';
import { hasAdminRights } from 'non-ui/login';

interface FerretCardProps {
	ferret: Ferret;
	deleteFerret: (idOfFerretToBeDeleted: number) => void;
}

interface FerretData {
	ferretId: number;
	ferretActive: boolean;
	ferretName: string;
	ferretOrder: string | number;
}


type Styles = ClassNameMap<"root" | "form" | "title" | "pos" | "editField" | "deleteButton">;
export type ErrorReport = Partial<FerretData>;
export type Validator = (values: FerretData) => ErrorReport;

const useStyles = makeStyles({
	root: {
		minWidth: 275,
		margin: 20
	},
	form: {
		width: '100%',
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	editField: {
		padding: '4px 0',
	},
	deleteButton: {
		color: 'yellow',
		backgroundColor: 'black',
	},
});


function FerretCard({ ferret, deleteFerret }: FerretCardProps) {
	const [editMode, setEditMode] = useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const classes: Styles = useStyles();
	const isAdminUser = hasAdminRights();

	const deleteHandler = (deleteAccepted: boolean, idOfFerretToBeDeleted?: number) => {
		setShowDeleteDialog(false);
		if (deleteAccepted && idOfFerretToBeDeleted) {
			setEditMode(false);
			deleteFerret(idOfFerretToBeDeleted);
		}
	}

	return (
		<>
			<DeleteWarningDialog open={showDeleteDialog} idOfFerretToBeDeleted={ferret.ferretId!} handleClose={deleteHandler} />
			<Card key={ferret.ferretId} className={classes.root}>
				{editMode && isAdminUser
					? editCardContent(ferret, setEditMode, setShowDeleteDialog, classes)
					: staticCardContent(ferret, setEditMode, classes, isAdminUser)
				}
			</Card>
		</>
	);
}

function staticCardContent(ferret: Ferret, setEditMode: (isSet: boolean) => void, classes: Styles, isAdminUser: boolean): React.ReactNode {
	return (
		<>
			<CardContent>
				<Typography className={classes.title} color="textSecondary" gutterBottom>{ferret.ferretActive ? 'Active' : 'Inactive'}</Typography>
				<Typography variant="h5" component="h2">
					{ferret.ferretName}
				</Typography>
				<Typography className={classes.pos} color="textSecondary">
					Sort Order: {ferret.ferretOrder}
				</Typography>
				<Typography variant="body2" component="p">
					Some stats to go here
				</Typography>
			</CardContent>
			<CardActions>
				{isAdminUser && (
					<IconButton onClick={() => setEditMode(true)}>
						<EditIcon />
					</IconButton>
				)}
			</CardActions>
		</>
	);
}

function editCardContent(ferret: Ferret, setEditMode: (isSet: boolean) => void, setShowDeleteDialog: (show: boolean) => void, classes: Styles): React.ReactNode {
	const initialValues: FerretData = createInitialFerret(ferret);
	const validator: Validator = ferretValidator();
	const submitHandler = (ferret: FerretData, { setSubmitting }: FormikHelpers<FerretData>) => {
		updateFerret(toFerret(ferret));
		setSubmitting(false);
		setEditMode(false);
	}

	return (
		<Formik initialValues={initialValues} validate={validator} onSubmit={submitHandler}>
			{({ submitForm, isSubmitting, touched, errors }) => (
				<Form className={classes.form}>
					<CardContent>
						<div className={classes.editField}>
							<Field component={TextField} label='Name' variant='outlined' name='ferretName' />
						</div>
						<div className={classes.editField}>
							<Field component={TextField} label='Order' variant='outlined' type='number' name='ferretOrder' />
						</div>
						<div className={classes.editField}>
							{ferret.ferretActive
								? (<FormControlLabel control={<Field component={Checkbox} type="checkbox" name="ferretActive" />} label="Active?" />)
								: (<Typography className={classes.title} color="textSecondary" gutterBottom>Inactive</Typography>)
							}
						</div>
					</CardContent>
					<CardActions>
						<IconButton onClick={submitForm}>
							<DoneIcon />
						</IconButton>
						<IconButton onClick={() => setEditMode(false)}>
							<CancelIcon />
						</IconButton>
						<IconButton onClick={() => setShowDeleteDialog(true)}>
							<DeleteForeverIcon className={classes.deleteButton} />
						</IconButton>
					</CardActions>
				</Form>
			)}
		</Formik>
	);
}

function createInitialFerret(ferret: Ferret): FerretData {
	return {
		ferretId: ferret.ferretId!,
		ferretActive: ferret.ferretActive,
		ferretName: ferret.ferretName,
		ferretOrder: ferret.ferretOrder.toString(),
	};
}

function toFerret(ferret: FerretData): Ferret {
	return {
		ferretId: ferret.ferretId,
		ferretActive: ferret.ferretActive,
		ferretName: ferret.ferretName,
		ferretOrder: (typeof ferret.ferretOrder === 'number') ? ferret.ferretOrder : parseInt(ferret.ferretOrder)
	};
}

function ferretValidator(): Validator {
	return (ferret: FerretData) => {
		const errors: ErrorReport = {};
		if (isBlankOrEmpty(ferret.ferretName))
			errors.ferretName = 'Required';
		if (typeof ferret.ferretOrder === 'number' && ferret.ferretOrder < 1)
			errors.ferretOrder = 'Must be > 0';
		else if (typeof ferret.ferretOrder === 'string' && isBlankOrEmpty(ferret.ferretOrder))
			errors.ferretOrder = 'Required';
		return errors;
	}
}

export default FerretCard;