import DateFnsUtils from '@date-io/date-fns';
import { createStyles, IconButton, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';
import { Ferret } from 'non-ui/data';
import React from 'react';

export interface FerretWeights {
	date: string;
	[key: string]: string | null;
}

type ErrorReport = Partial<FerretWeights>;
type Validator = (values: FerretWeights) => ErrorReport;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			width: '100%',
		},
		root: {
			padding: 5,
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		date: {
			width: '80%',
			margin: theme.spacing(1),
		},
		buttons: {
			paddingTop: 20,
			width: '100%',
			display: 'flex',
			justifyContent: 'space-evenly',
		},
		fields: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			width: '100%',
			padding: '10 10',
		},
		weightentry: {
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
		},
		textfield: {
			margin: theme.spacing(1),
			textAlign: 'right',
			width: '100%',
		}
	}),
);

export type FerretWeightsHandler = (values: FerretWeights) => void;

interface FerretWeightsFormProperties {
	ferrets: Ferret[];
	ferretWeightsHandler: FerretWeightsHandler;
	cancelHandler: () => void;
}

function FerretWeightsForm({ ferrets, ferretWeightsHandler, cancelHandler }: FerretWeightsFormProperties) {
	const classes = useStyles();
	const ferretNames: string[] = ferrets.map(f => f.ferretName);
	const initialValues: FerretWeights = createInitialValues(ferretNames);
	const validator: Validator = valuesValidator(ferretNames);

	const submitHandler = (values: FerretWeights, helpers: FormikHelpers<FerretWeights>) => {
		ferretWeightsHandler(values);
		helpers.setSubmitting(false);
	}

	return (
		<Formik
			initialValues={initialValues}
			validate={validator}
			onSubmit={submitHandler}
		>
			{({ submitForm, isSubmitting, touched, errors }) => (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Form className={classes.form}>
						{isSubmitting && <LinearProgress />}
						<div className={classes.root}>
							<Field className={classes.date} component={DatePicker} name="date" label="Date" format="dd MMM yyyy" />
							<div className={classes.fields}>
								{ferretNames.map(n => (
									<div className={classes.weightentry}>
										<Field className={classes.textfield} component={TextField} type='number' name={n} label={n} variant='outlined' />
									</div>
								))}
							</div>
							<div className={classes.buttons}>
								<IconButton onClick={submitForm}>
									<DoneIcon fontSize='large' />
								</IconButton>
								<IconButton onClick={cancelHandler}>
									<CancelIcon fontSize='large' />
								</IconButton>
							</div>
						</div>
					</Form>
				</MuiPickersUtilsProvider>
			)}
		</Formik>
	);
}

function createInitialValues(ferrets: string[]): FerretWeights {
	const vals: FerretWeights = {
		date: new Date().toDateString()
	};

	ferrets.forEach(f => vals[f] = null);

	return vals;
}

function valuesValidator(ferrets: string[]): Validator {
	return (values) => {
		const errors: ErrorReport = {};
		if (!values.date)
			errors.date = 'Required';

		ferrets.forEach(f => {
			if (!values[f])
				errors[f] = 'Required';
			else {
				try {
					Number.parseInt(values[f]!);
				} catch (e) {
					errors[f] = 'Must be numeric';
				}
			}
		})

		return errors;
	}
}

export default FerretWeightsForm;