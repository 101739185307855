import { createStyles, Fab, Grid, makeStyles, Theme, useTheme } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CanvasJSReact from "canvas/canvasjs.react";
import FerretWeightsForm, { FerretWeights } from "components/ferret-weight-form";
import SwipeableTemporaryDrawer from "components/swipeable-temporary-drawer";
import { Ferret, GraphDisplayData, MinimalFerretWeight, MinimalWeightDate } from "non-ui/data";
import { LineData, toLineData } from "non-ui/graphdata";
import { hasAdminRights } from "non-ui/login";
import { FerretState } from "non-ui/redux/store";
import { updateFerretWeights } from "non-ui/rest/weights";
import React from "react";
import { connect } from "react-redux";

interface FerretWeightsGraphProps {
	ferrets: Ferret[];
	graphDisplayData: GraphDisplayData[];
	linesAsSplines: boolean;
	updateWeights: (minmalWeightDate: MinimalWeightDate) => void;
}

const LargeScreenBreak = 'md';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: theme.typography.fontWeightRegular,
		},
		canvaschart: {
			marginTop: 10,
			paddingBottom: 10,
			[theme.breakpoints.up(LargeScreenBreak)]: {
				marginTop: 80,
			},
		},
		fabblock: {
			display: 'flex',
			justifyContent: 'flex-end'
		}
	}),
);

function FerretWeightsGraph({ ferrets, graphDisplayData, linesAsSplines, updateWeights }: FerretWeightsGraphProps) {
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const lineData: LineData[] = toLineData(graphDisplayData, linesAsSplines);
	const classes = useStyles();
	const isAdmin = hasAdminRights();
	const theme = useTheme();

	const options = {
		animationEnabled: true,
		zoomEnabled: true,
		// backgroundColor: "#FAFAFA",
		backgroundColor: theme.palette.background.default,
		axisX: {
			valueFormatString: "DD MMM YY",
			interval: 1,
			intervalType: "month",
			labelAngle: 260
		},
		axisY: {
			title: "Weight",
			prefix: "",
			suffix: "g",
			includeZero: false,
		},
		toolTip: {
			shared: true
		},
		legend: {
			cursor: "pointer",
			verticalAlign: "bottom",
			horizontalAlign: "center",
			dockInsidePlotArea: false,
		},
		data: lineData
	};

	const handler = (ferretWeights: FerretWeights) => {
		const actualDate = new Date(ferretWeights.date);
		const convertedWeights = convertWeights(ferretWeights);

		const weightData: MinimalWeightDate = {
			date: actualDate,
			ferretWeights: convertedWeights
		};
		updateWeights(weightData);
		setOpenDrawer(false);
	}

	return (
		<Grid item container direction='column'>

			<Grid container>
				<Grid item xs={1} />
				<Grid item xs={10}>
					<div className={classes.canvaschart}>
						<CanvasJSReact.CanvasJSChart options={options} />
					</div>
					{isAdmin && (
						<div className={classes.fabblock}>
							<Fab color="primary" aria-label="add" onClick={() => setOpenDrawer(true)}>
								<AddIcon />
							</Fab>
						</div>
					)}
				</Grid>
				<Grid item xs={1} />
			</Grid>

			{isAdmin && (
				<Grid container>
					<Grid item xs={1} />
					<Grid item xs={10}>
						<SwipeableTemporaryDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} anchor='right'>
							<FerretWeightsForm ferrets={ferrets} ferretWeightsHandler={handler} cancelHandler={() => setOpenDrawer(false)} />
						</SwipeableTemporaryDrawer>
					</Grid>
					<Grid item xs={1} />
				</Grid>
			)}

		</Grid>
	);
}

function convertWeights(ferretWeights: FerretWeights): MinimalFerretWeight[] {
	const weights: MinimalFerretWeight[] = [];

	for (var key in ferretWeights) {
		if (key !== 'date') {
			const weight: MinimalFerretWeight = { ferretName: key, ferretWeight: parseInt(ferretWeights[key]!) };
			weights.push(weight);
		}
	}
	return weights;
}

const mapStateToProps = (state: FerretState) => {
	return { ferrets: state.ferrets, graphDisplayData: state.graphDisplayData, linesAsSplines: state.localstore.linesAsSplines };
}

const mapActionsToProps = { updateWeights: updateFerretWeights };

export default connect(mapStateToProps, mapActionsToProps)(FerretWeightsGraph);
