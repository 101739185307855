import React from 'react';

interface ComponentState {
	hasError: boolean;
	error?: any;
}

class ErrorBoundary extends React.Component<object, ComponentState> {
  constructor(props : object) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error : any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(error : any, errorInfo : any) {
		const errType = typeof error;
		console.log('Well this is bad');
		console.log(`Error is of type ${errType} and looks like this: ${error}`);
  	// logJson('Meanwhile, Error Info: ', errorInfo);
	}

  render() {
    if (this.state.hasError) {
			return (
				<>
					<h1>Well, that was a shit idea</h1>
					The error says: {this.state.error}
				</>
			);
    }

    return this.props.children; 
  }
}
export default ErrorBoundary;