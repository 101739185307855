import { Reducer } from "redux";
import { AlertAction, SHOW_ALERT, AlertData, CLEAR_ALERT } from ".";

export const nullAlertData: AlertData = {
	level: 'none',
	message: ''
}

function reducer(state: AlertData = nullAlertData, { type, payload }: AlertAction): AlertData {
	switch (type) {
		case SHOW_ALERT:
		case CLEAR_ALERT:
				return payload;
		default:
			return state;
	}
}

const alerts: Reducer<AlertData, AlertAction> = reducer;

export default alerts;

