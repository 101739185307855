import { Fab, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SwipeableTemporaryDrawer from 'components/swipeable-temporary-drawer';
import { Ferret } from 'non-ui/data';
import { hasAdminRights } from 'non-ui/login';
import { FerretState } from 'non-ui/redux/store';
import { deleteFerretById } from 'non-ui/rest/ferrets';
import React from 'react';
import { connect } from 'react-redux';
import AddFerretForm from './add-ferret-form';
import FerretCard from './ferret-card';

interface FerretsProps {
	ferrets: Ferret[];
	deleteFerret: (idOfFerretToBeDeleted: number) => void;
}

function Ferrets({ ferrets, deleteFerret }: FerretsProps) {
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const isAdminUser = hasAdminRights();

	return (
		<>
		<Grid container direction="row">
			<Grid item xs={false} lg={2} />
			<Grid item xs={12} lg={8} container alignItems='center'>
				{ferrets.map(f => (<FerretCard ferret={f} deleteFerret={deleteFerret} />))}
				{isAdminUser && (
				<div>
				<Fab color="primary" aria-label="add" onClick={() => setOpenDrawer(true)}>
					<AddIcon />
				</Fab>
				</div>
				)}
			</Grid>
			<Grid item xs={false} lg={2} />
		</Grid>
		{isAdminUser && (
			<SwipeableTemporaryDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} anchor='right'>
				<AddFerretForm closeDrawer={() => setOpenDrawer(false)} />
			</SwipeableTemporaryDrawer>
		)}
		</>
	);
}

const mapStateToProps = (state: FerretState) => {
	return { ferrets: state.ferrets };
}

const mapActionsToProps = {
	deleteFerret: deleteFerretById
};

export default connect(mapStateToProps, mapActionsToProps)(Ferrets);
