export interface ServerConfig {
	scheme: string;
	ipAddress: string;
	port: number;
}

export interface Configuration {
	ferretServer : ServerConfig;
}

const prod : Configuration = {
	ferretServer: {
		scheme: 'https',
		ipAddress: 'pagey.org.uk',
		port: 8080
	},
 };
  
 const dev : Configuration = {
	ferretServer: {
		scheme: 'https',
		// ipAddress: '192.168.1.19',
		ipAddress: 'localhost',
		port: 8080
	},
 };

 export function isDevelopmentMode() {
	 return process.env.NODE_ENV === 'development';
 }

 export function isSecurityEnabled() {
	 return config.ferretServer.scheme === 'https';
 }
 
 export const config : Configuration = isDevelopmentMode() ? dev : prod;