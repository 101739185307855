const shouldCheck = (key: string, checkKeys: Array<String>): boolean => {
	if (checkKeys.length === 0)
		return true;
	return checkKeys.includes(key);
}

export function objectHasUnsetProperty(obj: Record<string, any>, checkKeys: Array<string> = []): boolean {
	for (var key in obj) {
		if (shouldCheck(key, checkKeys) && !isBlankOrEmpty(obj[key]))
			return true;
	}
	return false;
}

export function isBlankOrEmpty(value: string): boolean {
	if (value !== null)
		return value.trim() === "";
	return true;
}
