import { makeStyles } from '@material-ui/core/styles';
import { TableRowData } from 'non-ui/data';
import React from 'react';
import { WeightLabelComponent, WeightsSlider } from './weights-slider';

const useStyles = makeStyles({
	root: {
		width: '80%',
		margin: '0 auto',
	},
});

interface DateRangeSelectorProps {
	tableDataList: TableRowData[];
	setIndices: (lowerIndex: number, upperIndex: number) => void;
}

function formatDate(date: Date): string {
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
	const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
	return `${da}-${mo}-${ye}`;
}

export default function DateRangeSelector({ tableDataList, setIndices }: DateRangeSelectorProps) {
	const classes = useStyles();
	const maxIndex = tableDataList.length - 1;
	const initialMin = (maxIndex > 3) ? maxIndex - 4 : 0;

	const correctIndex = (index: number): number => maxIndex - index;

	const valuetext = (index: number) => {
		const date = tableDataList[correctIndex(index)].date;
		return formatDate(date);
	}

	const handleChange = (event: any, newValue: number | number[]) => {
		const numRange = newValue as number[];
		setIndices(correctIndex(numRange[1]), correctIndex(numRange[0]) + 1);
	};

	if (tableDataList.length === 0)
		return null;

	return (
		<div className={classes.root}>
			<WeightsSlider
				ValueLabelComponent={WeightLabelComponent}
				onChange={handleChange}
				valueLabelDisplay="on"
				aria-labelledby="range-slider"
				getAriaValueText={valuetext}
				valueLabelFormat={valuetext}
				step={1}
				marks
				min={0}
				max={maxIndex}
				defaultValue={[initialMin, maxIndex]}
			/>
		</div>
	);
}