const StringToBooleanConvertor = (raw : string) => raw.toLowerCase() === 'true';

export function loadBooleanPropertyFromLocalStorage(property: string, defaultIfNull: boolean = false) : boolean {
	return loadPropertyFromLocalStorage(property, defaultIfNull, StringToBooleanConvertor);
}

function loadPropertyFromLocalStorage<T>(property : string, defaultIfNull : T, convertor: (raw : string) => T) : T {
  const rawStringValue = localStorage.getItem(property);
	return rawStringValue === null
		? defaultIfNull
		: convertor(rawStringValue);
}
