import { Button, createStyles, LinearProgress, makeStyles, Theme, Avatar } from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import { LoginCredentials } from "non-ui/data";
import { isBlankOrEmpty, objectHasUnsetProperty } from "non-ui/utilities";
import dick from 'assets/img/dick.jpg';
import { login } from "non-ui/rest/login";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		outercontainer: {
			width: '100%',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
		},
		screensizedependent: {
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '50%',
			}
		},
		logintitle: {
			// font-family: 'Arial',
			fontSize: '20pt',
			color: '#333333',
			lineHeight: '1.2',
			textAlign: 'center',
			paddingTop: '10vh',
			paddingBottom: '4vh',
		},
		avatar: {
			margin: '0 auto',
			display: 'flex',
			justifyContent: 'center',
		},
		avatarImage: {
			width: 120,
			height: 120
		},
		loginform: {
			margin: '0 auto',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		username: {
			marginTop: '6vh',
			marginBottom: '3vh',
		},
		password: {
			marginBottom: '5vh',
		},

		buttonwrapper: {
			width: '100%',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
		},
		fieldsContainer: {
			height: 200,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-evenly',
			alignItems: 'center'
		},

		button: {
			fontFamily: 'Arial',
			fontSize: '14pt',
			color: '#fff',
			lineHeight: '1.2',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0 .3em',
			width: '60%',
			height: '3em',
			backgroundColor: '#57b846',
			borderRadius: '3.5em',
			boxShadow: '0 10pt 30pt 0 rgba(87, 184, 70, 0.5)',
		},
	}),
);

type ErrorReport = Partial<LoginCredentials>;
type Validator = (loginCredentials: LoginCredentials) => ErrorReport;

interface LocationData {
	location: {
		pathname: string;
	};
}

function Login() {
	const classes = useStyles();
	const initialValues: LoginCredentials = emptyLoginCredentials();
	const history = useHistory();
	const loc = useLocation<LocationData>();

	const redirector = () => {
		const { location } = loc.state || { location: { pathname: "/" } };
		history.replace(location);
	}

	const submitHandler = (credentials: LoginCredentials, helpers: FormikHelpers<LoginCredentials>) => {
		login(credentials.username, credentials.password, redirector);
		helpers.setSubmitting(false);
	}

	return (
		<div className={classes.outercontainer}>
			<div className={classes.screensizedependent}>
				<div className={classes.logintitle}>Would you mind awfully logging in?</div>
				<div className={classes.avatar}><Avatar src={dick} alt="dick" className={classes.avatarImage} /></div>
				<Formik
					initialValues={initialValues}
					validate={credentialsValidator()}
					onSubmit={submitHandler}
				>
					{({ submitForm, isSubmitting, touched, errors }) => (
						<Form className={`${classes.loginform} ${classes.screensizedependent}`}>
							{isSubmitting && <LinearProgress />}
							<div className={classes.fieldsContainer}>
								<div className={classes.username}>
									<Field component={TextField} type='text' name={'username'} label={'Username'} variant='outlined' />
								</div>
								<div className={classes.password}>
									<Field component={TextField} type='password' name={'password'} label={'Password'} variant='outlined' />
								</div>
							</div>
							<div className={classes.buttonwrapper}>
								<Button
									className={classes.button}
									variant="contained"
									color="primary"
									disabled={isSubmitting || (touched.password && touched.username && objectHasUnsetProperty(errors))}
									onClick={submitForm}
								>
									Login
            		</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}

function emptyLoginCredentials(): LoginCredentials {
	return {
		username: '',
		password: ''
	};
}

function credentialsValidator(): Validator {
	return (loginCredentials: LoginCredentials) => {
		const errors: ErrorReport = {};
		if (isBlankOrEmpty(loginCredentials.username))
			errors.username = 'Required';
		if (isBlankOrEmpty(loginCredentials.password))
			errors.password = 'Required';
		return errors;
	}
}


export default Login;

