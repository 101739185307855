import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import PetsIcon from '@material-ui/icons/Pets';
import TimelineIcon from '@material-ui/icons/Timeline';
import TocIcon from '@material-ui/icons/Toc';
import TopMenuItem from './topmenu-item';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		textAlign: 'center'
	},
	imageIcon: {
		display: 'flex',
		height: 'inherit',
		width: 'inherit'
	},
}));

export interface MenuProps {
	graphpath: string;
	tablepath: string;
	ferretspath: string;
	settingspath: string;
}


function TopBar(menuProps: MenuProps) {
	const classes = useStyles();
	const fontsize = 'large';

	return (
		<div className={classes.root}>
			<AppBar position="static" elevation={0}>
				<Toolbar>
					<TopMenuItem exact routerPath={menuProps.graphpath}>
						<TimelineIcon fontSize={fontsize} />
					</TopMenuItem>
					<TopMenuItem routerPath={menuProps.tablepath}>
						<TocIcon />
					</TopMenuItem>
					<TopMenuItem routerPath={menuProps.ferretspath}>
						<PetsIcon />
					</TopMenuItem>
					<Typography variant="h5" className={classes.title}>Ferrets</Typography>
					<TopMenuItem routerPath={menuProps.settingspath}>
						<MenuIcon />
					</TopMenuItem>
				</Toolbar>
			</AppBar>
		</div>
	);
}

export default TopBar;