import { Button, Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { exportDatabase } from 'non-ui/rest/admin';
import { clearUserToken } from 'non-ui/rest/login';
import React from 'react';
import { useHistory } from 'react-router';
import { Styles } from '.';

interface ActionsCardProps {
	classes: Styles;
}

export type InnerStyles = ClassNameMap<"buttonContainer">;

const useStyles = makeStyles({
	buttonContainer: {
		height: 150,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		alignItems: 'center'
	},
});


function ActionsCard({ classes }: ActionsCardProps) {
	const history = useHistory();
	const innerStyles : InnerStyles = useStyles();

	const logout = () => {
		clearUserToken();
		history.replace({ pathname: "/" });
	}

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography variant="h5" component="h2">
					Actions
				</Typography>
			</CardContent>
			<CardActions />
			<div className={innerStyles.buttonContainer}>
				<Button onClick={logout} variant="contained" color="secondary">Logout</Button>
				<Button onClick={exportDatabase} variant="contained" color="secondary">Export</Button>
			</div>
		</Card>
	);
}

export default ActionsCard;