import { GraphDataPoint, GraphDisplayData } from "non-ui/data";

const LinetypeSpline: string = 'spline'
const LinetypeLine: string = 'line'

export interface LineData {
	name: string;
	type: string;
	axisYType: string;
	showInLegend: boolean;
	markerSize: number;
	yValueFormatString: string;
	dataPoints: GraphDataPoint[];
}

export function toLineData(graphDataDisplayList : GraphDisplayData[], displaySpline : boolean): LineData[] {
	const convertor = dataConvertor(displaySpline);
	return graphDataDisplayList.map(convertor);
}

function dataConvertor(displaySpline : boolean) : (gdd : GraphDisplayData) => LineData {
	const linetype = displaySpline ? LinetypeSpline : LinetypeLine;

	return (gdd) => newLineDataInstance(gdd, linetype);
}

function correctDate(graphDataPoint : GraphDataPoint) : GraphDataPoint {
	return {
		x: new Date(graphDataPoint.x),
		y: graphDataPoint.y
	};
}

function newLineDataInstance(graphDataDisplay : GraphDisplayData, linetype : string) : LineData {
	return {
		name: graphDataDisplay.ferretName,
		type: linetype,
		axisYType: 'secondary',
		showInLegend: true,
		markerSize: 0,
		yValueFormatString: '#,###',
		dataPoints: graphDataDisplay.weights.map(correctDate)
	};
}