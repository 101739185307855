import { LocalStore } from "non-ui/data";

export const UPDATE_LOCAL_STORE : string = 'localstore:update-local-store';

export const INCLUDE_INACTIVE_FERRETS : string = 'includeInactiveFerrets';
export const GRAPH_LINES_AS_SPLINES : string = 'graphLinesAsSplines';
export const USE_PAGINATED_TABLE : string = 'usePaginatedTable';
export const USER_TOKEN_KEY = 'userToken';

export interface LocalStoreAction {
  type: typeof UPDATE_LOCAL_STORE;
  payload: LocalStore;
}