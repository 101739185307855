import { GraphDisplayData, MinimalWeightDate, TableRowData } from "non-ui/data";
import { FerretAction } from "non-ui/redux/ferrets";
import { GraphDisplayDataAction } from "non-ui/redux/graphdisplaydata";
import { replaceGraphDisplayData } from "non-ui/redux/graphdisplaydata/graphdisplaydata-actions";
import { TableDisplayDataAction } from "non-ui/redux/tabledisplaydata";
import { replaceTableDisplayData } from "non-ui/redux/tabledisplaydata/tabledisplaydata-actions";
import { Dispatch } from "redux";
import { convertJsonResponse, createPostPayload, fetchAndCheckResponse, fetchEverything, handleRestError, SuppressIf401 } from ".";
import { UrlBuilder } from "./rest-request-builders";

const WEIGHT_PATH = 'weight';

export function fetchTableDisplayData() {
	const url = new UrlBuilder()
		.path(WEIGHT_PATH)
		.path('table')
		.appendInactiveFerretsParameter()
		.build();

	return (dispatch: Dispatch<TableDisplayDataAction>) => fetchAndCheckResponse(url)
		.then(resp => convertJsonResponse<TableRowData[]>(resp))
		.then(tableDisplayData => fixDate(tableDisplayData, instantiateTableRowDate))
		.then(tableDisplayData => dispatch(replaceTableDisplayData(tableDisplayData)))
		.catch(response => handleRestError(response, 'fetch table display data', SuppressIf401));
}

export function fetchGraphDisplayData() {
	const url = new UrlBuilder()
		.path(WEIGHT_PATH)
		.path('graph')
		.appendInactiveFerretsParameter()
		.build();

	return (dispatch: Dispatch<GraphDisplayDataAction>) => fetchAndCheckResponse(url)
		.then(resp => convertJsonResponse<GraphDisplayData[]>(resp))
		.then(graphDisplayData => dispatch(replaceGraphDisplayData(graphDisplayData)))
		.catch(response => handleRestError(response, 'fetch graph display data', SuppressIf401));
}

export function updateFerretWeights(minmalWeightDate: MinimalWeightDate) {
	const url = new UrlBuilder()
		.path('weight')
		.build();

	const payload = createPostPayload(minmalWeightDate);
	return (dispatch: Dispatch<FerretAction|GraphDisplayDataAction|TableDisplayDataAction>) => fetchAndCheckResponse(url, payload)
		.then(() => fetchEverything()(dispatch))
		.catch(response => handleRestError(response, 'add ferret weights'));
}

function instantiateTableRowDate(tableRowData: TableRowData): TableRowData {
	return {
		date: new Date(tableRowData.date),
		ferretWeights: tableRowData.ferretWeights
	}
}

function fixDate<T>(values: T[], corrector: (val : T) => T): Promise<T[]> {
	const adjusted: T[] = values.map(corrector);
	return Promise.resolve(adjusted);
}

