import * as React from 'react';
import {
	Route,
	Redirect,
	RouteProps,
} from 'react-router-dom';
import { UserToken } from 'non-ui/data';

interface PrivateRouteProps extends RouteProps {
	// tslint:disable-next-line:no-any
	component: any;
	loginPath: string;
	userToken?: UserToken;
}

export function PrivateRoute({ component: Component, loginPath, userToken, ...rest }: PrivateRouteProps) {
	// const str = (userToken) ? JSON.stringify(userToken) : 'unset';
	// console.trace('private root user token is ' + str);

	return (
		<Route
			{...rest}
			render={(routeProps) =>
				userToken 
				? (
					<Component {...routeProps} />
				) 
				: (
					<Redirect
						to={{
							pathname: loginPath,
							state: routeProps
						}}
					/>
				)
			}
		/>
	);
};