import { AlertAction, SHOW_ALERT, CLEAR_ALERT, MessageLevel } from ".";
import { nullAlertData } from "./alert-reducers";


export function showAlert(level : MessageLevel, message : string) : AlertAction {
	return {
		type: SHOW_ALERT,
		payload: { level: level, message: message }
	};
} 

export function clearAlert() : AlertAction {
	return {
		type: CLEAR_ALERT,
		payload: nullAlertData
	};
} 
