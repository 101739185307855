import { Reducer } from "redux";
import { INCLUDE_INACTIVE_FERRETS, GRAPH_LINES_AS_SPLINES, LocalStoreAction, UPDATE_LOCAL_STORE, USER_TOKEN_KEY, USE_PAGINATED_TABLE } from ".";
import { LocalStore } from "non-ui/data";
import { readUserToken } from "non-ui/localstore/jwt-decode";
import { loadBooleanPropertyFromLocalStorage } from "non-ui/localstore";


export const INITIAL_LOCAL_STORE : LocalStore = {
	includeInactiveFerrets: loadBooleanPropertyFromLocalStorage(INCLUDE_INACTIVE_FERRETS),
	linesAsSplines: loadBooleanPropertyFromLocalStorage(GRAPH_LINES_AS_SPLINES),
	usePaginatedTable: loadBooleanPropertyFromLocalStorage(USE_PAGINATED_TABLE, true),
	userToken: readUserToken(USER_TOKEN_KEY), 
}

function reducer(state: LocalStore = INITIAL_LOCAL_STORE, { type, payload }: LocalStoreAction): LocalStore {
	switch (type) {
		case UPDATE_LOCAL_STORE:
			return payload;
		default:
			return state;
	}
}


const localstore: Reducer<LocalStore, LocalStoreAction> = reducer;

export default localstore;