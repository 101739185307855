import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
		type: 'light',
    primary: {
      main: '#0F2F2F',
    },
    secondary: {
      main: '#B8860B',
    },
		background: {
			paper: '#32CD32',
			default: '#DCDCDC',
		},
  },
	typography: {
		fontFamily: "Metropolis, 'Roboto'",
		fontWeightLight: 100,
	}
});

export default theme;