import { IconButton, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core';
import { Ferret } from 'non-ui/data';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { isBlankOrEmpty } from 'non-ui/utilities';
import { addFerret } from 'non-ui/rest/ferrets';

interface AddFerretFormProps {
	closeDrawer: () => void;
}

interface FerretData {
	ferretName: string;
	ferretOrder: string | number;
}

type Styles = ClassNameMap<"form" | "editField" | "buttons">;
export type ErrorReport = Partial<FerretData>;
export type Validator = (values: FerretData) => ErrorReport;

const useStyles = makeStyles({
	form: {
		width: '100%',
	},
	editField: {
		padding: '5px 10px',
	},
	buttons: {
		paddingTop: 20,
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
	},
});


function AddFerretForm({ closeDrawer }: AddFerretFormProps) {
	const classes: Styles = useStyles();

	const initialValues: FerretData = createEmptyFerretData();
	const validator: Validator = ferretValidator();
	const submitHandler = (ferret: FerretData, { setSubmitting }: FormikHelpers<FerretData>) => {
		addFerret(toFerret(ferret));
		setSubmitting(false);
		closeDrawer();
	}

	return (
		<Formik initialValues={initialValues} validate={validator} onSubmit={submitHandler}>
			{({ submitForm, isSubmitting, touched, errors }) => (
				<Form className={classes.form}>
					<div className={classes.editField}>
						<Typography variant="h5" component="h2">New Ferret</Typography>
					</div>
					<div className={classes.editField}>
						<Field component={TextField} label='Name' variant='outlined' name='ferretName' />
					</div>
					<div className={classes.editField}>
						<Field component={TextField} label='Order' variant='outlined' type='number' name='ferretOrder' />
					</div>
					<div className={classes.buttons}>
						<IconButton onClick={submitForm}>
							<DoneIcon fontSize='large' />
						</IconButton>
						<IconButton onClick={() => closeDrawer()}>
							<CancelIcon fontSize='large' />
						</IconButton>
					</div>
				</Form>
			)}
		</Formik>
	);
}

function createEmptyFerretData(): FerretData {
	return {
		ferretName: '',
		ferretOrder: '99',
	};
}

function toFerret(ferret: FerretData): Ferret {
	return {
		ferretId: null,
		ferretActive: true,
		ferretName: ferret.ferretName,
		ferretOrder: (typeof ferret.ferretOrder === 'number') ? ferret.ferretOrder : parseInt(ferret.ferretOrder)
	};
}

function ferretValidator(): Validator {
	return (ferret: FerretData) => {
		const errors: ErrorReport = {};
		if (isBlankOrEmpty(ferret.ferretName))
			errors.ferretName = 'Required';
		if (typeof ferret.ferretOrder === 'number' && ferret.ferretOrder < 1)
			errors.ferretOrder = 'Must be > 0';
		else if (typeof ferret.ferretOrder === 'string' && isBlankOrEmpty(ferret.ferretOrder))
			errors.ferretOrder = 'Required';
		return errors;
	}
}

export default AddFerretForm;