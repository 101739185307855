import { UserRole, UserToken } from "non-ui/data";
import { isSecurityEnabled } from "configuration";

const LocalHardwiredToken: string = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJvdmVybG9yZCIsInJvbGUiOiJST0xFX0FETUlOIiwiZXhwIjoxNjIxMzMwNDk0LCJpYXQiOjE2MjEzMzAxOTR9.cKE20Tvat0VSReBMCzjD4RJ0eWRW8_dfoW84NdtfTlpewLHDRRWOesBMzzmyuHvMfSj15bK8mCpwLjY1lHiviw';

interface DecodedJwt {
	sub: string;
	role: UserRole;
	exp: number;
	iat: number;
}

export function readUserToken(userTokenKey: string): UserToken | undefined {
	const rawtokenstr: string | null = isSecurityEnabled()
		? localStorage.getItem(userTokenKey)
		: LocalHardwiredToken;

	return parseJwt(rawtokenstr);
}

function parseJwt(token: string | null): UserToken | undefined {
	if (!token)
		return undefined;

	try {
		const payload = token.split('.')[1];
		const rawjson = atob(payload);
		const decodedJwt: DecodedJwt = JSON.parse(rawjson) as DecodedJwt;

		return {
			username: decodedJwt.sub,
			role: decodedJwt.role,
			token: token
		};
	} catch (InvalidCharacterError) {
		console.error('unreadable token in local store: ' + token);
		return undefined;
	}
}
